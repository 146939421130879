import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { EditorModule } from '@tinymce/tinymce-angular';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GaugeChartModule } from 'angular-gauge-chart';
import { GoogleChartsModule } from 'angular-google-charts';
import { UserIdleModule } from 'angular-user-idle';
import { AdminPlansComponent } from './admin-plans/admin-plans.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AgentPermissionComponent } from './agent-permission/agent-permission.component';
import { AgentSettingaComponent } from './agent-settinga/agent-settinga.component';
import { AgentsComponent } from './agents/agents.component';
import { AgentsformComponent } from './agents/agentsform.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { ForgotPwdComponent } from './auth/forgot-pwd/forgot-pwd.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout.component';
import { DashboradComponent } from './dashborad/dashborad.component';
import { DepartmentComponent } from './department/department.component';
import { GlobalSettingsComponent } from './global-settings/global-settings.component';
import { HomeComponent } from './home/home.component';
import { EmailComponent } from './mc/email/email.component';
import { McComponent } from './mc/mc.component';
import { MenuComponent } from './menu/menu.component';
import { ProfileComponent } from './profile/profile.component';
import { SafePipe } from './safe.pipe';
import { ServerService } from './services/server.service';
import { TicketComposeComponent } from './ticket-compose/ticket-compose.component';
import { TicketCreateNewComponent } from './ticket-create-new/ticket-create-new.component';
import { TicketForwardComponent } from './ticket-forward/ticket-forward.component';
import { TicketReportComponent } from './ticket-report/ticket-report.component';
import { TicketViewThreadComponent } from './ticket-view-thread/ticket-view-thread.component';
import { TicketViewComponent } from './ticket-view/ticket-view.component';
import { TicketingSystemNewComponent } from './ticketing-system-new/ticketing-system-new.component';
import { TicketingSystemComponent } from './ticketing-system/ticketing-system.component';
// import { WpPayComponent } from './wp-pay/wp-pay.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import * as CanvasJSAngularChart from '../assets/lib/canvasjs.angular.component';
import { AgentGroupsComponent } from './agent-groups/agent-groups.component';
import { BulkEmailComponent } from './bulk-email/bulk-email.component';
import { BulkMailListComponent } from './bulk-mail-list/bulk-mail-list.component';
import { EmailAdvanceSearchComponent } from './email-advance-search/email-advance-search.component';
import { EmailDashboardComponent } from './email-dashboard/email-dashboard.component';
import { EmailGroupsComponent } from './email-groups/email-groups.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { EmailTicketReportComponent } from './email-ticket-report/email-ticket-report.component';
import { EmailsUnassignedComponent } from './emails-unassigned/emails-unassigned.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { InternalKbComponent } from './internal-kb/internal-kb.component';
import { KbListViewComponent } from './kb-list-view/kb-list-view.component';
import { MrvoipApiKbComponent } from './mrvoip-api-kb/mrvoip-api-kb.component';
import { SpamListComponent } from './spam-list/spam-list.component';
import { TicketSignatureComponent } from './ticket-signature/ticket-signature.component';
import { TicketTemplateComponent } from './ticket-template/ticket-template.component';
import { WhitelistMailComponent } from './whitelist-mail/whitelist-mail.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
import { CommonModule } from '@angular/common';
import { ApproveArticleComponent } from './approve-article/approve-article.component';


const appRoutes: Routes = [
    {
        path :'', component:McComponent
    },
    // {
    //     path :'test', component:TestComponent
    // },
    {
        path :'dashboard', component:DashboradComponent
    },{
        path :'login', component:LoginComponent
    },{
        path :'forgot-pwd', component:ForgotPwdComponent
    },{
        path :'profile', component:ProfileComponent
    },{
        path :'agents', component:AgentsComponent
    },{
        path :'mc', component:McComponent
    },
    {
        path :'admin-settings', component:AdminSettingsComponent
    },
    {
        path :'logout', component:LogoutComponent
    },
    
    {
        path :'ticketing-system', component:TicketingSystemComponent
    },
    {
        path :'ticket-view', component:TicketViewComponent
    },
    {
        path :'ticket-compose', component:TicketComposeComponent
    }, {
        path :'agent-settings', component:AgentSettingaComponent
    },
    {
        path :'ticket-report', component:TicketReportComponent
    },
    {
        path :'issueTypes', component:DepartmentComponent
    },
  
    {
        path :'ticketing-system-new', component:TicketingSystemNewComponent
    },
    {
        path :'ticket-forward', component:TicketForwardComponent
    },
   
    {
        path :'ticket-view-thread', component:TicketViewThreadComponent
    },
    {
        path :'ticket-create-new', component:TicketCreateNewComponent
    },
    {
        path :'agent-group', component:AgentGroupsComponent
    },
    
    {
        path :'bulk-mail-list', component:BulkMailListComponent
    },

    {
        path :'ticket-sign', component:TicketSignatureComponent
    },{
        path :'ticket-template', component:TicketTemplateComponent
    },{
        path :'spam-list', component:SpamListComponent
    },{
        path :'email-settings',component:EmailSettingsComponent
    },{
        path :'E-L',component:ExternalLoginComponent
    },
    {
        path :'approve-articles',component:ApproveArticleComponent
    },
    {
        path :'unassinged-tickets',component:EmailsUnassignedComponent
    },{
        path :'email-dashboard',component:EmailDashboardComponent
    },{
        path :'email-adv-search',component:EmailAdvanceSearchComponent
    },{
        path :'internal-kb',component:InternalKbComponent
    },{
        path :'whitelist-mails',component:WhitelistMailComponent
    },{
        path :'email-ticket-report',component:EmailTicketReportComponent
    },
    {
        path :'private-kb/kb-list-view/:data', component:KbListViewComponent
    },
     {
        path: 'kb',
        loadChildren: () => import('./app-knowledge-base/app-knowledge-base.module').then(m => m.AppKnowledgeBaseModule)      
      },
      {
        path: 'kb-web',
        loadChildren: () => import('./kbweb-knowledge-base/kbweb-knowledge-base.module').then(m => m.KbwebKnowledgeBaseModule)      
      },
      {
        path: 'kb-ios',
        loadChildren: () => import('./ios-knowledge-base/ios-knowledge-base.module').then(m => m.IosKnowledgeBaseModule)      
      },
      {
        path: 'kb-android',
        loadChildren: () => import('./android-knowledge-base/android-knowledge-base.module').then(m => m.AndroidKnowledgeBaseModule)      
      },
     {
        path: 'mrvoip-kb',
        loadChildren: () => import('./mrvoip-knowledge-base/mrvoip-knowledge-base.module').then(m => m.MrvoipKnowledgeBaseModule)      
      },
      {
        path: 'private',
        loadChildren: () => import('./private-kb/private-kb.module').then(m => m.PrivateKbModule)      
      },{
        path: 'faqs',
        loadChildren: () => import('./faqs/faqs.module').then(m => m.FaqsModule)      
      },
      {
        path: 'forum',
        loadChildren: () => import('./forum/forum.module').then(m => m.ForumModule)      
      }
];


    
@NgModule({
  declarations: [
    AppComponent,
    TicketingSystemNewComponent,
    McComponent,
    MenuComponent,
    HomeComponent,
    AuthComponent,
    LoginComponent,
    ForgotPwdComponent,
    ProfileComponent,
    AgentsComponent,
    AgentsformComponent,
    EmailComponent,
    LogoutComponent,
    AdminSettingsComponent,
    TicketingSystemComponent,
    TicketViewComponent,
    TicketComposeComponent,
    DepartmentComponent,
    TicketReportComponent,
    TicketForwardComponent,
    TicketViewThreadComponent,
    TicketCreateNewComponent,
    AppSettingsComponent,
    GlobalSettingsComponent,
    AgentPermissionComponent,
    AgentSettingaComponent,
    SafePipe,
    AdminPlansComponent,
    LogoutComponent,
    EmailGroupsComponent,
    BulkEmailComponent,
    AgentGroupsComponent,
    BulkMailListComponent,
    TicketSignatureComponent,
    TicketTemplateComponent,
    TicketingSystemComponent,
    EmailSettingsComponent,
    ExternalLoginComponent,
    EmailsUnassignedComponent,
    EmailDashboardComponent,   EmailAdvanceSearchComponent, LoginComponent, InternalKbComponent, WhitelistMailComponent, EmailTicketReportComponent, MrvoipApiKbComponent, KbListViewComponent, ApproveArticleComponent
    // CustomWallboardSixComponent,
    // NetwrixReportComponent
    
  
  ],
  imports: [
    NgbModule,
    BrowserModule,CommonModule,
    ReactiveFormsModule,GoogleChartsModule,FormsModule,
    HttpClientModule,EditorModule,  GaugeChartModule,
    // SocialLoginModule,
    UserIdleModule.forRoot({idle: 10, timeout: 10, ping: 10}),
    RouterModule.forRoot(appRoutes,{ useHash: true }),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireMessagingModule,
    // RichTextEditorAllModule,
    InfiniteScrollModule,
    NoopAnimationsModule,
    MatChipsModule,
    MatInputModule, MatIconModule,DragDropModule,MatSelectModule
  ],
  providers: [DatePipe,
    ServerService,
//     {
//     provide: AuthServiceConfig,
//     useFactory: provideConfig
//   },
  Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
