import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ServiceService } from '../../app/app-knowledge-base/services/service.service';

@Component({
  selector: 'app-approve-article',
  templateUrl: './approve-article.component.html',
  styleUrls: ['./approve-article.component.css']
})
export class ApproveArticleComponent implements OnInit {
  category_list;
  uadmin_id;
  admin_id;
  recordNotFound = false;
  category_items;
  catItemsnotFound=false;
  selectedID;
  admin_permission;
  user_type;
  profile_image;
  
  constructor(private serverService: ServiceService,private router:Router, private route: ActivatedRoute ) {

  
    
   }

  ngOnInit(): void {
    
    this.uadmin_id = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
    this.profile_image = localStorage.getItem('profile_image');
    this.getcategory();
    this.admin_permission = localStorage.getItem('admin_permision');
    this.user_type = localStorage.getItem('user_type');
    this.selectedID = this.route.snapshot.queryParamMap.get('selectedID');   
    
     
  }
  // privatearticle(){
  //   alert('test');
  //   this.router.navigate(['/private/desk']);
  // }
  getcategory(){
    let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"selectcategoryPending","type":"web,app"}}';
    this.serverService.sendserver(api_req).subscribe((response: any)=>{
      console.log(response)
      if(response.status == true && response.result.data.length > 0){
       
        this.category_list = response.result.data;
 
        if(this.selectedID =='undefined' || this.selectedID ==undefined || this.selectedID ==null){
          let id=response.result.data[0].id;
          let Jqueryid='panel-body_'+id;
          // $('.accordion-body').removeClass('show'); 
          // setTimeout(() => {
          // $('#'+id).addClass('collapse'); 
          // $('#'+id).addClass('show'); 
          // }, 1000);
          
          this.ShowSinglecategory(Jqueryid,id);
      }
      if(this.selectedID !='undefined' && this.selectedID !=undefined && this.selectedID !=null){
        this.selectedID = atob(this.selectedID);
        let id='panel-body_'+this.selectedID;
        //  $('.accordion-body').removeClass('show'); 
        //  setTimeout(() => {
        //  $('#'+id).addClass('collapse'); 
        //  $('#'+id).addClass('show'); 
        //  }, 100);
      
         this.ShowSinglecategory(id,this.selectedID);         
        
       }
    } else {
      this.recordNotFound = true

    }
  },(error: any)=>{
      console.log(error)
  });
 
}
ShowSinglecategory(id,category){
 if(!$('#'+id).hasClass('show')){//restrict if already open
  let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"ShowSinglecategoryPending","id":"'+category+'"}}';
  this.serverService.sendserver(api_req).subscribe((response: any)=>{
    console.log(response)
    if(response.status == "true"){
     
      this.category_items = response.data;
      if(this.category_items < 1){
        this.catItemsnotFound=true
      }else{
        this.catItemsnotFound=false
      }
      console.log(this.catItemsnotFound)
    }
},(error: any)=>{
    console.log(error)
});
 }

 //To show the single id and hide others
  $('.accordion-body').removeClass('show'); 
  setTimeout(() => {
  $('#'+id).addClass('collapse'); 
  $('#'+id).addClass('show'); 
  }, 100);
 
 
}
displaycat(){
  let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"display"}}';

  this.serverService.sendserver(api_req).subscribe((response:any) => {
    if(response.status=='true'){
     
      this.category_list = response.data;
      if(this.category_list.length==0)
      this.recordNotFound = true;
    }  
  }, 
  (error)=>{
      console.log(error);
  });
}
editpost(id,category_id) {
  var edit_id = btoa(id); // Base64 encode the String
  
  if(category_id == '4'){
    this.router.navigate(['/kb-ios/editpage'], { queryParams: { id: edit_id }});
  } else if(category_id == '5'){
    this.router.navigate(['/kb-android/editpage'], { queryParams: { id: edit_id }});
  } else if(category_id == '6'){
    this.router.navigate(['/kb-web/editpage'], { queryParams: { id: edit_id }});
  } else {
    this.router.navigate(['/kb/editpage'], { queryParams: { id: edit_id }});
  }

}
// editpost(id){
//   let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"editpage","id":"'+id+'"}}	';

// this.serverService.sendServer(api_req).subscribe((response:any) => {
//   if(response.result.data==1){
//     this.router.navigate(['/editpage'])
//   }

// }, 
// (error)=>{
//     console.log(error);
// });
// }


deletepost(id,category_id){
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.value) {
let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"delete","id":"'+id+'"}}	';

this.serverService.sendserver(api_req).subscribe((response:any) => {
  if(response.result.data==1){
    Swal.fire(
      'Deleted!',
      'success'
    );
     
  //  let j_id='panel-body_'+category_id;
  //  $('#'+j_id).removeClass('show')
  //   this.ShowSinglecategory(j_id,category_id);
    //this.getcategory();
    this.ToggleCollapse(category_id);
  }

}, 
(error)=>{
    console.log(error);
});
    }
  })
}

approvePost(id,category_id){
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Approve it!'
  }).then((result) => {
    if (result.value) {
let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"approval","id":"'+id+'"}}	';

this.serverService.sendserver(api_req).subscribe((response:any) => {
  if(response.result.data==1){
    Swal.fire(
      'Approved!',
      'success'
    );
     
  //  let j_id='panel-body_'+category_id;
  //  $('#'+j_id).removeClass('show')
    this.ToggleCollapse(category_id);





    //this.getcategory();
  }

}, 
(error)=>{
    console.log(error);
});
    }
  })
}





ToggleCollapse(category){
    let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"ShowSinglecategoryPending","id":"'+category+'"}}';
  this.serverService.sendserver(api_req).subscribe((response: any)=>{
    console.log(response)
    if(response.status == "true"){
     
      this.category_items = response.data;
      if(this.category_items < 1){
        this.catItemsnotFound=true
      }else{
        this.catItemsnotFound=false
      }
      console.log(this.catItemsnotFound)
    }
},(error: any)=>{
    console.log(error)
});
}
}
