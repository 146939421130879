import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
import Swal from 'sweetalert2'
declare var iziToast:any;
@Component({
  selector: 'app-email-advance-search',
  templateUrl: './email-advance-search.component.html',
  styleUrls: ['./email-advance-search.component.css']
})
export class EmailAdvanceSearchComponent implements OnInit {
  constructor(private serverService: ServerService,public router: Router) { }

  user_id;
  admin_id;
  queue_list_all;
  admin_permission;
  access_token;
  user_type;
  admin_type;
  show_admin_set=false;
  filterON=false;
  show_caller_id='1';
  customer_details;
  customerList1;
  SearchResult;
  offset_count = 0;
  pageLimit=20;
  paginationData:any ={"info":"hide"};
  from_date_csv;
  to_date_csv;
  cus_name_csv;
  search_val_csv;
  res;//response for csv API
  recordFound=false;
  ngOnInit(): void {

    $(document).ready(function () {
			// $("#filtersection").hide();
			// $("#filtertags").hide();
		
			// $("#showfilter").click(function() {
			// 	$("#treeselect").show();
			// });
		 
			//tree
		
			$('.tree li:has(ul)').addClass('parent_li').find(' > span').attr('title', 'Expand this branch');
			$('.tree li.parent_li > span').each(function(){
				var children = $(this).parent('li.parent_li').find(' > ul > li');
				if (children.is(":visible")) {
					children.hide('fast');
					$(this).attr('title', 'Collapse this branch');
				}else{
					children.hide('fast');
					$(this).attr('title', 'Expand this branch').find(' > i').addClass('glyphicon-plus').removeClass('glyphicon-minus');
				
				}
			});
		 
		
			$('.tree li> ul > li > span').on('click', function (e) {
				$("#filterinput").html('<div class="input-group" id="filtergroup"><span class="input-group-addon">'+$(this).attr("data-fq")+'</span><input type="text" id="fiterinputtext" class="form-control" value=""/></div>');
				// $("#treeselect").hide();
				// $("#filtersection").show();
				// $("#fiterinputtext").focus();
				e.stopPropagation();
			});
			$('.tree li.parent_li > span').on('click', function (e) {
				var children = $(this).parent('li.parent_li').find(' > ul > li');
				if (children.is(":visible")) {
					children.hide('fast');
					$(this).attr('title', 'Expand this branch').find(' > i').addClass('glyphicon-plus').removeClass('glyphicon-minus');
				} else {
					children.show('fast');
					$(this).attr('title', 'Collapse this branch').find(' > i').addClass('glyphicon-minus').removeClass('glyphicon-plus');
				}
				e.stopPropagation();
			});
		
		});
    this.user_id = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
    this.admin_permission = localStorage.getItem('admin_permision');
    this.access_token = localStorage.getItem('access_token');
   	this.user_type = localStorage.getItem('user_type');
 

    	if (this.user_type == 'Super Admin') {
			this.user_type = 1;
           this.show_admin_set=true;
  
		}
		else if (this.user_type == 'Admin' || this.admin_permission =='1') {
			this.user_type = 2;
			this.admin_type='Admin';
             this.show_admin_set=true;
		}
		else {
			this.user_type = 3;
			this.admin_type='';
            this.show_admin_set=false;
		}
this.getAllCustomer();
  }
  
  getAllCustomer(){
		// {"operation":"ticket","moduleType":"ticket","api_type":"web","access_token":"","element_data":{"action":"getMyAliasEmails","admin_id":"1203"}}
		//old Action name : getCustomerDetasils
		let access_token: any = localStorage.getItem('access_token');
		let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"geterpCustomerDetasils","admin_id":"' + this.admin_id + '"}}';
	  
		this.serverService.sendServer(api_req).subscribe((response: any) => {
		  if (response.status == true) {        // console.log(response);
			this.customer_details = response.result.data; 
		 
		  }
		},
		  (error) => {
			console.log(error);
		  });
	  
	  }
	getUserIdsFirstWay($event) {
		let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
		this.customerList1 = [];
	
		if (userId.length > 3) {		 
			this.customerList1 = this.searchFromArray(this.customer_details, userId);				
		}
	  }  
	
	  searchFromArray(arr, regex) {
		let matches = [], i;	
		for (i = 0; i < arr.length; i++) {
		  if (arr[i].customerName.match(regex)) {			
			matches.push(arr[i]);
		  }
		}
		return matches;
	  };
	  
	  GetIDFromArray(arr, regex) {
		let matches ='';
		console.log(arr)
		for (let i = 0; i < arr.length; i++) {
 
		  if (arr[i].customerName == regex) {
			matches= arr[i].customerId;
		  } 
		}
	return matches;
	  };
	  listDataInfo(list_data){

		list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
		list_data.order_by_name = list_data.order_by_name == undefined ? "history.callid" : list_data.order_by_name;
		list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
		list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
		list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
		return list_data;
	}

	advancedSearch(data){
		console.log(data)
		var list_data= this.listDataInfo(data);

		let from_date=$('#adv_from_date').val();
		let to_date=$('#adv_to_date').val();
    let cus_name='';
    
    if($('#userIdFirstWay').val()!= undefined ||$('#userIdFirstWay').val()!='')
       cus_name=$('#userIdFirstWay').val();
   
		let search_val=$('#adv_search_text').val();
		if(from_date==''&&to_date==''&&cus_name==''&&search_val==""){
			iziToast.warning({
				message:"There is no input found",
				position:"topRight"
			})
			return false;
		}
this.from_date_csv=from_date;
this.to_date_csv=to_date;
this.cus_name_csv=cus_name;
this.search_val_csv=search_val;
		let access_token: any = localStorage.getItem('access_token');
		let api_req: any = '{"operation":"advanceTicketReport", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"advance_ticket_reports","user_id":"' + this.user_id + '","customer_name":"' + cus_name + '","search_text":"' + search_val + '","fromDate":"' + from_date + '","toDate":"' + to_date + '","limit":"'+list_data.limit+'","offset":"'+list_data.offset+'"}}';
	  
		this.serverService.sendServer(api_req).subscribe((response: any) => {
		  if (response.status == true) {  
                  this.SearchResult=response.result.data;
                     this.offset_count = list_data.offset;				 

	            	this.paginationData = this.serverService.pagination({'offset':list_data.offset, 'total':response.result.list_info.total, 'page_limit' :this.pageLimit });
	            	this.recordFound = this.SearchResult.length == 0 ? false : true;
					if(this.recordFound==false){
						iziToast.warning({
							message:"No records found",
							position:"topRight"
						})
					}
					$("html, body").animate({
						scrollTop: $(
						  'html, body').get(0).scrollHeight
					}, 1000);    
			 
		 
		  }
		},
		  (error) => {
			console.log(error);
		  });
	}
	ExportCSV(){			  
			 
			let api_req:any = new Object();
			let agents_req:any = new Object();
			agents_req.action="export_ticket_reports";
			agents_req.user_id=localStorage.getItem('userId');
			agents_req.customer_name = this.cus_name_csv;
			agents_req.search_text = this.search_val_csv;
			agents_req.fromDate = this.from_date_csv;
			agents_req.toDate = this.to_date_csv;
			api_req.operation="advanceTicketReport";
			api_req.moduleType="ticket";
			api_req.api_type="web";
			api_req.access_token=localStorage.getItem('access_token');
			api_req.element_data = agents_req;
			console.log(api_req);
				this.serverService.sendServer(api_req).subscribe((response:any) => {
				console.log(response);
	
		  
				  if(response.result.status==true){
				  this.res = response;
				  var arrStr = encodeURIComponent(JSON.stringify(this.res));
				  // document.location.href = 'https://ticketing.ringq.ai/api/storage/contact/download.php?res='+arrStr;
			   
				
				  var url = 'https://ticketing.ringq.ai/api/storage/email/email_export_report.php';
				  var form = $('<form target="_blank" action="' + url + '" method="post">' +
					'<input type="text" name="res" value="' + arrStr + '" />' +
					'</form>');
				  $('body').append(form);
				  form.submit();
				
				} else {
				  iziToast.warning({
					message: "No Records Found. Please try again",
					position: 'topRight'
				});
				}
				}, 
			(error)=>{
				console.log(error);
			});
		  }
	
	
}
