import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'omni-channel';
    public file_path: string = "";
    templateAuthView = false;
    E_Login = false;
    scroll_mybutton;
    constructor(private router:Router) { }
    
ngOnInit(): void {
    var self=this;
    this.scroll_mybutton = document.getElementById("ScrollmyBtn");
    window.onscroll = function() {self.scrollFunction()};
}

    onActivate(event) {
        this.file_path=this.router.url;
        console.log(this.router.url);
         this.E_Login=false;
// alert(this.router.url.split('?')[0])

        if (localStorage.getItem('access_token')) {
          this.templateAuthView=false;
          if(this.router.url.split('?')[0]== '/E-L'){
            this.E_Login=true;
            this.router.navigate([this.router.url]);
          }
        } 
        else if(this.router.url == '/forgot-pwd'){
          this.router.navigate(['/forgot-pwd']);
        }
        else if(this.router.url.split('?')[0] == '/E-L'){

                this.E_Login=true;
                this.router.navigate([this.router.url]);
            }
          else{
            this.templateAuthView=true;
            this.router.navigate(['/login']);

    //  if(this.router.url == '/E-L'){
    //   this.E_Login=true;
    //             this.router.navigate(['/E-L']);
    //   }
           }
        // alert(this.E_Login);
    }
    // When the user scrolls down 20px from the top of the document, show the button

  scrollFunction() {
    if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
      this.scroll_mybutton.style.display = "block";
    } else {
      this.scroll_mybutton.style.display = "none";
    }
  }
  
  // When the user clicks on the button, scroll to the top of the document
    topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
